:root {
    --font-fallbacks: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    --nypl-blue: #1b7fa7;
    --nypl-red: #E32B31;
    --banner-margin: 1rem;
    --nypl-brown: #333333;
}

.form_group .login_label:after {
    content: " (Required)";
    color: var(--nypl-red);
}

.welcome_banner {
    width: 45rem !important;
    margin-top: var(--banner-margin);
    margin-bottom: var(--banner-margin);
    background-color: var(--nypl-brown) !important;
}

.login_form_banner {
    width: 25rem;
    margin-top: var(--banner-margin);
    margin-bottom: var(--banner-margin);
}


.link {
    color: var(--nypl-red) !important;
}               

.link:hover, .link:focus {
    color: var(--nypl-blue) !important;
    border-color: var(--nypl-blue) !important;
}

.submit_button {
    width: 95% !important;
    border-radius: 30px;
}
:root {
    --font-fallbacks: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    --padding: 20px
}

.table_field {
    text-align: left;
}

.table_data {
    text-align: right;
}

.table_data,
.table_field,
.table_title {
    font-family: var(--font-fallbacks);
    color: rgb(51, 51, 51);
}

.table_field {
    font-weight: 500;
    font-size: 18px;
}

.table_data{
    font-weight: 300;
    font-size: 18px;
}

.table_title {
    font-size: 20px;
}
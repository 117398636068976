// required imports
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

//adding nypl primary colors

$nypl-primary: #1b7fa7;
$nypl-danger: #e32b31;

$custom-colors: (
  "nypl-primary": $nypl-primary,
  "nypl-danger": $nypl-danger,
);

$input-focus-width: 2px;
$shadow-thickness: 0 0 0 $input-focus-width;

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

// changing invalid box shadow color for input fields
.was-validated .form-select:invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow: $shadow-thickness $nypl-danger !important;
}

.was-validated .form-select:valid:focus,
.was-validated .form-control:valid:focus {
  box-shadow: $shadow-thickness $green-400 !important;
}

//changing primary box shadow for input fields
.form-select:focus,
.form-control:focus {
  box-shadow: $shadow-thickness $nypl-primary !important;
}

.btn-close {
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.5) !important;
}

//buttons
.btn:focus {
  --bs-btn-focus-shadow-rgb: 0, 0, 0 !important;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.5) !important;
}

@import "~bootstrap/scss/bootstrap.scss";

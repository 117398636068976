.skip_link {
    margin-right: 1rem;
    position: absolute;
    transform: translateX(-200%);
    transition: transform 0.3s;
}

.skip_link:focus {
    position: static;
    transform: translateX(0);
    box-shadow: 1px 1px 2px 2px #0f465c;
    outline: #135772 solid 3px;
}
/*! system-font.css v2.0.2 | CC0-1.0 License | github.com/jonathantneal/system-font-css */
@font-face {
    font-family: system-ui;
    font-style: normal;
    font-weight: 300;
    src: local(".SFNSText-Light"), local(".HelveticaNeueDeskInterface-Light"), local(".LucidaGrandeUI"), local("Segoe UI Light"), local("Ubuntu Light"), local("Roboto-Light"), local("DroidSans"), local("Tahoma")
}

@font-face {
    font-family: system-ui;
    font-style: italic;
    font-weight: 300;
    src: local(".SFNSText-LightItalic"), local(".HelveticaNeueDeskInterface-Italic"), local(".LucidaGrandeUI"), local("Segoe UI Light Italic"), local("Ubuntu Light Italic"), local("Roboto-LightItalic"), local("DroidSans"), local("Tahoma")
}

@font-face {
    font-family: system-ui;
    font-style: normal;
    font-weight: 400;
    src: local(".SFNSText-Regular"), local(".HelveticaNeueDeskInterface-Regular"), local(".LucidaGrandeUI"), local("Segoe UI"), local("Ubuntu"), local("Roboto-Regular"), local("DroidSans"), local("Tahoma")
}

@font-face {
    font-family: system-ui;
    font-style: italic;
    font-weight: 400;
    src: local(".SFNSText-Italic"), local(".HelveticaNeueDeskInterface-Italic"), local(".LucidaGrandeUI"), local("Segoe UI Italic"), local("Ubuntu Italic"), local("Roboto-Italic"), local("DroidSans"), local("Tahoma")
}

@font-face {
    font-family: system-ui;
    font-style: normal;
    font-weight: 500;
    src: local(".SFNSText-Medium"), local(".HelveticaNeueDeskInterface-MediumP4"), local(".LucidaGrandeUI"), local("Segoe UI Semibold"), local("Ubuntu Medium"), local("Roboto-Medium"), local("DroidSans-Bold"), local("Tahoma Bold")
}

@font-face {
    font-family: system-ui;
    font-style: italic;
    font-weight: 500;
    src: local(".SFNSText-MediumItalic"), local(".HelveticaNeueDeskInterface-MediumItalicP4"), local(".LucidaGrandeUI"), local("Segoe UI Semibold Italic"), local("Ubuntu Medium Italic"), local("Roboto-MediumItalic"), local("DroidSans-Bold"), local("Tahoma Bold")
}

@font-face {
    font-family: system-ui;
    font-style: normal;
    font-weight: 700;
    src: local(".SFNSText-Bold"), local(".HelveticaNeueDeskInterface-Bold"), local(".LucidaGrandeUI"), local("Segoe UI Bold"), local("Ubuntu Bold"), local("Roboto-Bold"), local("DroidSans-Bold"), local("Tahoma Bold")
}

@font-face {
    font-family: system-ui;
    font-style: italic;
    font-weight: 700;
    src: local(".SFNSText-BoldItalic"), local(".HelveticaNeueDeskInterface-BoldItalic"), local(".LucidaGrandeUI"), local("Segoe UI Bold Italic"), local("Ubuntu Bold Italic"), local("Roboto-BoldItalic"), local("DroidSans-Bold"), local("Tahoma Bold")
}

.footer {
    overflow-y: hidden;
    padding: 15px 20px 100px;
    background: #54514a;
    color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
    min-height: 360px;
    position: relative;
    clear: both
}

.footer a {
    color: #fff;
    font-weight: 400;
    margin: 0 .2rem;
    text-decoration: none
}

.footer a:hover {
    text-decoration: underline
}

.footer a:focus {
    background-color: #54514a;
    outline: solid 2px #fff
}

.footer .svgIcon {
    fill: #fff
}

.footer .logoText {
    position: absolute;
    top: 20px
}

.footer .logoText .nyplTextLogo {
    height: 94px;
    width: 115px;
}

@media only screen and (min-width: 768px) {
    .footer .logoText {
        top: 20px;
        left: 6%
    }
}

@media only screen and (min-width: 1024px) {
    .footer .logoText {
        top: 35px
    }
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 75px
}

.footer-content .footerLinks {
    font-size: 16px;
    line-height: 35px;
    margin: 0;
    padding: 0;
    order: 2;
    list-style: none;
    list-style-type: none;
    text-align: right;
    width: 100%
}

@media only screen and (min-width: 768px) {
    .footer-content .footerLinks {
        width: auto
    }
}

.footer-content .footerLinks .linkItemList {
    list-style-type: none
}

.footer-content .footerLinks .linkItemList li {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden
}

.footer-content .footerLinks .linkItemList li:first-child {
    position: relative;
    left: 0;
    height: auto;
    width: 100%
}

@media only screen and (min-width: 768px) {
    .footer-content .footerLinks .linkItemList li {
        position: relative;
        left: 0;
        height: auto;
        width: 100%
    }
}

.footer-content .footerLinks ul {
    padding: 0
}

@media only screen and (min-width: 768px) {
    .footer-content .footerLinks li {
        float: left;
        width: 135px
    }

    .footer-content .footerLinks li:first-child {
        width: 110px
    }
}

@media only screen and (min-width: 1024px) {
    .footer-content .footerLinks li {
        width: 155px
    }

    .footer-content .footerLinks li:first-child {
        margin-right: 110px
    }

    .footer-content .footerLinks li:nth-child(2) {
        margin-right: 60px
    }
}

@media only screen and (min-width: 1500px) {

    .footer-content .footerLinks li:first-child,
    .footer-content .footerLinks li:nth-child(2) {
        width: 155px;
        margin-right: 0
    }
}

@media only screen and (min-width: 768px) {
    .footer-content .footerLinks {
        order: 1;
        margin: 0;
        font-size: 13px;
        line-height: 25px;
        text-align: left
    }
}

@media only screen and (min-width: 1024px) {
    .footer-content .footerLinks {
        font-size: 14px;
        font-weight: 400;
        line-height: 30px
    }
}

@media only screen and (min-width: 1500px) {
    .footer-content .footerLinks {
        margin-right: 70px;
        order: 2
    }
}

.footer-content .socialMedia {
    order: 1;
    margin: 20px 0 15px 0;
    list-style-type: none;
    text-align: right;
    width: 200px
}

.footer-content .socialMedia li {
    display: inline-block;
    width: 45px
}

.footer-content .socialMedia li a {
    display: block;
    color: #fff;
    font-size: 40px;
    line-height: 45px;
    text-decoration: none
}

.footer-content .socialMedia li a svg {
    width: 36px;
    height: 36px
}

@media only screen and (min-width: 768px) {
    .footer-content .socialMedia li a {
        font-size: 50px
    }
}

.footer-content .socialMedia li [class^=icon-],
.footer-content .socialMedia li [class*=" icon-"] {
    padding: 0
}

.footer-content .socialMedia li [data-icon]:before,
.footer-content .socialMedia li [class^=icon-]:before,
.footer-content .socialMedia li [class*=" icon-"]:before {
    margin: 0 5px 0 0 !important
}

@media only screen and (min-width: 768px) {
    .footer-content .socialMedia {
        margin-top: 30px
    }
}

@media only screen and (min-width: 1024px) {
    .footer-content .socialMedia {
        margin-top: 30px
    }
}

@media only screen and (min-width: 1500px) {
    .footer-content .socialMedia {
        margin-top: 0;
        width: 280px
    }
}

@media only screen and (min-width: 768px) {
    .footer-content {
        margin: 0;
        right: 6%;
        position: absolute;
        align-items: flex-end
    }
}

@media only screen and (min-width: 1500px) {
    .footer-content {
        flex-direction: row-reverse;
        align-items: flex-start
    }
}

@media only screen and (min-width: 768px) {
    .footer-content--secondary {
        position: absolute;
        left: 6%;
        top: 140px;
        width: 400px
    }
}

@media only screen and (min-width: 1024px) {
    .footer-content--secondary {
        top: 170px
    }
}

@media only screen and (min-width: 1500px) {
    .footer-content--secondary {
        top: 140px
    }
}

.footer-content--secondary .facade img {
    width: 100%;
    height: auto
}

.footer-content--secondary .copyright {
    margin-bottom: auto;
    /* padding-bottom: 1rem; */
    /* margin: 35px 0 0 0; */
    font-size: 12px;
    font-family: -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
    text-align: right
}

@media only screen and (min-width: 768px) {
    .footer-content--secondary .copyright {
        font-size: 11px
    }
}

@media only screen and (min-width: 1024px) {
    .footer-content--secondary .copyright {
        font-size: 13px
    }
}

@media only screen and (min-width: 768px) {
    .footer {
        padding: 15px 0
    }
}

@media only screen and (min-width: 1024px) {
    .footer {
        padding: 30px 0
    }
}
.home_body{
    background-image: url(../../assets/img/winter10.jpg);
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* */ 
    min-height: 80vh;
    display: flex;
    flex-direction: column;
}

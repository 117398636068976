@import "/src/pages/home/Home.module.css";

:root {
    --padding: 20px;
}

.page_header {
    text-align: center;
    padding: var(--padding);
    font-family: var(--font-fallbacks);
    font-size: 2rem !important;
    font-weight: 400 !important;
}

.formGroup .requiredLabel:after {
    content: " (Required)";
    color: var(--nypl-red);
}

.formGroup {
    margin: 10px;
}

.requiredLabel {
    white-space: nowrap;
}